import * as React from "react";
import { Link, graphql, navigate } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import SectionSubheading from "../components/SectionSubheading/SectionSubheading";
import Footer from "../components/Footer/Footer";
import CategoriesCarousel from "../components/CategoriesCarousel/CategoriesCarousel";
import CategoryLink from "../components/CategoryLink/CategoryLink";
import ToolLinks from "../components/ToolLinks/ToolLinks";
import SearchTools from "../components/SearchTools/SearchTools";

import "./HomePage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

export const query = graphql`
  query {
    allAiTool(
      sort: { fields: title, order: ASC }
      filter: { homepageTool: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          tags
          summaryDescription
          screenshot
          rating
          reviewCount
        }
      }
    }
    allCategory {
      edges {
        node {
          title
          toolCount
        }
      }
    }
  }
`;

const HomePage = ({ data }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  const aiToolsPerPage = 10; // Number of aiTools to load per page
  const [aiTools, setAITools] = React.useState(
    data.allAiTool.edges.slice(0, 20)
  );

  // const [hasMore, setHasMore] = React.useState(
  //   data.allAiTool.edges.length >= aiToolsPerPage
  // );

  // const loadMoreAITools = () => {
  //   const currentAIToolCount = aiTools.length;
  //   const nextAITools = data.allAiTool.edges.slice(
  //     currentAIToolCount,
  //     currentAIToolCount + aiToolsPerPage
  //   );

  //   setAITools([...aiTools, ...nextAITools]);

  //   if (nextAITools.length < aiToolsPerPage) {
  //     setHasMore(false);
  //   }
  // };

  // React.useEffect(() => {
  //   // Attach event listener to load more aiTools when user scrolls to the bottom
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   const windowHeight =
  //     "innerHeight" in window
  //       ? window.innerHeight
  //       : document.documentElement.offsetHeight;
  //   const body = document.body;
  //   const html = document.documentElement;
  //   const docHeight = Math.max(
  //     body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight
  //   );
  //   const windowBottom = windowHeight + window.pageYOffset;

  //   if (windowBottom >= docHeight && hasMore) {
  //     loadMoreAITools();
  //   }
  // };

  React.useEffect(() => {
    dispatch({
      type: "setCurrentCategory",
      category: {},
    });
  }, []);

  return (
    <Page>
      <TopBar hideSearch />
      <Section>
        <div className="home-page-hero">
          <SectionHeading>
            <span style={{ color: "#3578A0" }}>
              AI Product Reviews | World's Number 1 Trusted AI Directory
            </span>
          </SectionHeading>
          <div className="home-page-hero-description">
            <span style={{ color: "#3578A0" }}>
              Find amazing Tools. Read Reviews. Write Reviews.
            </span>
          </div>
          <div className="home-page-hero-description">
            <span style={{ color: "#3578A0" }}>Find tools you can trust.</span>
          </div>
          <SearchTools initialSearchTerm={""} />
        </div>
      </Section>
      <Section backgroundColor="#F1F3F3">
        <div>
          <SectionSubheading>
            <span style={{ textTransform: "uppercase" }}>
              Browse Categories
            </span>
          </SectionSubheading>
        </div>
        <div className="categories">
          <CategoriesCarousel
            slides={data.allCategory.edges
              .sort((a, b) => b.node.toolCount - a.node.toolCount)
              .map((category) => (
                <CategoryLink title={category.node.title} />
              ))}
          />
        </div>
      </Section>
      <Section>
        <div>
          <SectionSubheading>
            <span style={{ textTransform: "uppercase" }}>
              Featured AI Tools
            </span>
          </SectionSubheading>
        </div>
        <ToolLinks tools={aiTools.map((aiTool) => aiTool.node)} />
      </Section>
      <Footer />
    </Page>
  );
};

export default HomePage;

export const Head = () => {
  return (
    <SEO
      index
      follow
      title={"AI Product Reviews | World's No. 1 Trusted AI Directory"}
      description={
        "Discover latest tools, unbiased reviews, user insights, guides, coupons & more. Your hub for all things AI. Explore now!"
      }
    />
  );
};
