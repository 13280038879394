import React from "react";
import { Link } from "gatsby";

import "./CategoryLink.css";

const slugGenerator = require("../../util/slugGenerator");

export default function Category({ title }) {
  return (
    <Link to={`/${slugGenerator(title)}/`}>
      <div className="category-link">{title}</div>
    </Link>
  );
}
