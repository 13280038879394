import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./CategoriesCarousel.css";

export default function CategoriesCarousel({ slides }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 2,
    },
  };

  const [slidesToShow, setSlidesToShow] = useState(20); // Number of slides to show initially

  if (typeof window === "undefined") {
    return <div />;
  }

  return (
    <Carousel
      responsive={responsive}
      sliderClass="carousel-slider"
      afterChange={(currentSlide) => {
        setSlidesToShow(slidesToShow + 50);
      }}
    >
      {slides.slice(0, slidesToShow).map((slide, index) => (
        <div key={index} style={{ padding: 10 }}>
          {slide}
        </div>
      ))}
    </Carousel>
  );
}
